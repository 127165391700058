import styled from 'styled-components'
import media from 'styles/media'

import { H2Light } from 'components/atoms/Typography'

type HeroContainerType = {
  memoizedContentHeight: number
  memoizedTextContentHeight: number
}

export const HeroLableText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${media.xl.max} {
    gap: 12px;
  }

  ${media.lg.max} {
    width: 100%;

    position: absolute;
    top: 0px;
    transform: translate(0, -100%);
    padding: 24px;

    background-color: white;
  }
`

export const StyledH2Light = styled(H2Light)`
  ${media.sm.max} {
    font-size: 20px;
    line-height: auto;
  }
`

export const HeroContainer = styled.div<HeroContainerType>`
  margin: 0px auto;
  padding: 0px 32px;

  position: relative;

  display: flex;
  flex-direction: column;
  margin-bottom: ${({ memoizedContentHeight }) =>
    memoizedContentHeight && `${memoizedContentHeight / 2}px`};

  ${media.xxl.max} {
    padding-left: 0px;
    padding-right: 0px;
  }
  ${media.sm.max} {
    margin-bottom: ${({ memoizedContentHeight, memoizedTextContentHeight }) =>
      memoizedContentHeight &&
      memoizedTextContentHeight &&
      `${memoizedContentHeight - memoizedTextContentHeight}px`};
  }
`

export const ImageWrapper = styled.div`
  width: 100%;

  min-height: 400px;
  max-height: 76vh;

  margin: 0px auto;
  margin-top: 64px;

  position: relative;
  box-sizing: border-box;

  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    max-height: 76vh;

    position: static;
  }

  ${media.sm.max} {
    margin: 0px;
  }
`

export const HeroLable = styled.div`
  max-height: 300px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${media.lg.max} {
    position: static;
    align-items: stretch;
  }
`

export const HeroLableContent = styled.div`
  width: calc(100vw - (2 * 120px));
  max-width: 1136px;

  padding: 48px 64px;

  position: relative;
  transform: translate(0, -50%);

  display: flex;
  justify-content: space-between;
  gap: 32px;

  background-color: white;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);

  ${media.lg.max} {
    width: calc(100vw - (2 * 120px));
    position: relative;
    bottom: 0px;

    padding: 0px;
    gap: 0px;

    transform: translate(0%, 0%);

    display: flex;
    flex-direction: column;
    align-items: center;
  }
  ${media.md.max} {
    width: calc(100vw - (2 * 60px));
  }
  ${media.sm.max} {
    width: calc(100vw - (2 * 16px));
  }
`

export const HorizontalHr = styled.hr`
  box-sizing: content-box;
  height: 100%;
  width: 1px;
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.gray05};
  margin: 0;
  flex: 1 1;

  ${media.lg.max} {
    width: 100%;
    height: 1px;

    display: block;
  }
`
