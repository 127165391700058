import styled from 'styled-components'
import media from 'styles/media'

export const Wrapper = styled.div`
  height: 576px;
  max-width: 653px;

  flex: 1;

  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f3f3f3;

  padding: 40px;

  .swiper {
    width: 100%;
    height: 100%;
  }

  ${media.xl.max} {
    flex: none;
    width: 600px;
  }
  ${media.md.max} {
    width: 100vw;
    max-width: none;
  }
  ${media.sm.max} {
    height: fit-content;
  }
`

export const FlatWrapper = styled.div`
  text-decoration: none;
`

export const FlatDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 1;
`

export const FlatImageWrapper = styled.div`
  position: absolute;
  width: calc(65% + 40px);
  height: calc(68% + 40px);
  top: calc(50% + 32px);
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;

  ${media.sm.max} {
    position: static;
    transform: unset;
    width: 100%;
    height: 270px;
  }
`

export const NavigationWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 24px;
`

export const NavigationButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`
