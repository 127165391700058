import React from 'react'
import { Link } from 'gatsby'

import { Button, ButtonLine } from 'components/atoms/Button'
import { H3Bold, TextBody1 } from 'components/atoms/Typography/Typography.style'
import { ImageType } from 'types/image'
import {
  ContentContainer,
  ContentContainerWraper,
  ContentText,
  SmartDesignContainer,
  SmartDesignContent,
  SmartDesignContentWraper,
  StyledH1Light,
  StyledH2Light,
} from './SmartDesign.styles'

import { FlatPreview } from './FlatPreview'

type SmartDesignProps = {
  heading: string
  subHeading: string
  contentBox: {
    heading: string
    description: string
    buttonText: string
    buttonLink: string
  }
  flats: Array<{
    image: ImageType
    number: string
    floor: string
    rooms: string
    area: string
    link: string
  }>
}

export const SmartDesign: React.FC<SmartDesignProps> = ({
  heading,
  subHeading,
  contentBox,
  flats,
}) => {
  return (
    <SmartDesignContainer padding={[100, 80]} margin={[0, 'auto']}>
      <StyledH1Light
        as="h2"
        align="center"
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      <SmartDesignContentWraper>
        <SmartDesignContent>
          <StyledH2Light
            as="h3"
            dangerouslySetInnerHTML={{ __html: subHeading }}
          />
          <ContentContainerWraper>
            <ContentContainer>
              <ContentText>
                <H3Bold
                  dangerouslySetInnerHTML={{
                    __html: contentBox.heading,
                  }}
                />
                <TextBody1
                  dangerouslySetInnerHTML={{
                    __html: contentBox.description,
                  }}
                />
              </ContentText>
              <Button TextButton2 as={Link} to={contentBox.buttonLink}>
                <ButtonLine
                  dangerouslySetInnerHTML={{ __html: contentBox.buttonText }}
                />
              </Button>
            </ContentContainer>
          </ContentContainerWraper>
        </SmartDesignContent>
        <FlatPreview flats={flats} />
      </SmartDesignContentWraper>
    </SmartDesignContainer>
  )
}
