/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import styled from 'styled-components'
import media from 'styles/media'

import { ImageType } from 'types/image'

import { Container, ViewContainer } from 'components/atoms/Container'
import { H1Light } from 'components/atoms/Typography'

import { ItemsType } from 'components/modules/Carusela'

const ImageCarusela = React.lazy(() => import('components/modules/Carusela'))
const Carusela = React.lazy(
  () => import('components/modules/Carusela/DefaultCarusela')
)

const StyledH1Light = styled(H1Light)`
  margin-bottom: 48px;

  ${media.md.max} {
    margin-bottom: 24px;
  }
  ${media.sm.max} {
    text-align: left;
  }
`

const QualityOfLifeContainer = styled(Container)`
  max-width: calc(1136px + 32px);

  ${media.xl.max} {
    padding-left: 80px;
    padding-right: 80px;
  }
  ${media.lg.max} {
    padding-top: 80px;
  }
  ${media.md.max} {
    padding: 32px 0px 32px 16px;
  }
  ${media.sm.max} {
    padding-top: 80px;
  }
`

type TileType = {
  heading: string
  description: string
  image: ImageType
  buttonText?: string
  buttonLink?: string
}

type QualityOfLifeProps = {
  heading: string
  tiles: TileType[]
}

export const QualityOfLife: React.FC<QualityOfLifeProps> = ({
  heading,
  tiles,
}) => {
  const CaruselaItems = tiles.map((item) => ({
    front: {
      title: item.heading,
      image: {
        src: item.image.src,
        alt: item.image.alt!,
      },
    },
    back: {
      title: item.heading,
      text: item.description,
      image: {
        src: item.image.src,
        alt: item.image.alt!,
      },
      buttonLink: item.buttonLink,
      buttonText: item.buttonText,
    },
  }))

  let ImageCaruselaItems: ItemsType[] = [...CaruselaItems]

  while (ImageCaruselaItems.length < 4 || ImageCaruselaItems.length === 0) {
    ImageCaruselaItems = [...ImageCaruselaItems, ...CaruselaItems]
  }

  return (
    <QualityOfLifeContainer padding={[0, 16, 100, 16]} margin={[0, 'auto']}>
      <StyledH1Light
        as="h2"
        align="center"
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      <ViewContainer breakpoint="xl">
        <React.Suspense fallback={<></>}>
          <ImageCarusela items={ImageCaruselaItems} />
        </React.Suspense>
      </ViewContainer>

      <ViewContainer initialHidden breakpoint="xl">
        <React.Suspense fallback={<></>}>
          <Carusela items={CaruselaItems} />
        </React.Suspense>
      </ViewContainer>
    </QualityOfLifeContainer>
  )
}
