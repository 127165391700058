import React, { useState } from 'react'
import SwiperInstance from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link } from 'gatsby'

import { ReactComponent as ArrowLeft } from 'assets/icons/arrowLeft.svg'
import { ReactComponent as ArrowRight } from 'assets/icons/arrowRight.svg'

import { ImageType } from 'types/image'

import { LazyImage } from 'components/atoms/Image'
import { H3Bold, TextBody1 } from 'components/atoms/Typography/Typography.style'
import { IconButton } from 'components/atoms/Button'
import {
  Wrapper,
  FlatWrapper,
  FlatDescriptionWrapper,
  FlatImageWrapper,
  NavigationWrapper,
  NavigationButtonsWrapper,
} from './FlatPreview.styles'

type Props = {
  flats: Array<{
    image: ImageType
    number: string
    floor: string
    rooms: string
    area: string
    link: string
  }>
}

export const FlatPreview: React.FC<Props> = ({ flats }) => {
  const [swiper, setSwiper] = useState<SwiperInstance | null>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const goPrev = () => {
    swiper?.slidePrev()
  }

  const goNext = () => {
    swiper?.slideNext()
  }

  return (
    <Wrapper>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        grabCursor
        onSwiper={(initSwiper: SwiperInstance) => setSwiper(initSwiper)}
        onSlideChange={() => setActiveIndex(swiper?.realIndex!)}
      >
        {flats?.map(({ image, number, floor, rooms, area, link }) => (
          <SwiperSlide key={number}>
            <FlatWrapper as={Link} to={link ?? ''}>
              <FlatDescriptionWrapper>
                <H3Bold dangerouslySetInnerHTML={{ __html: number }} />
                <H3Bold dangerouslySetInnerHTML={{ __html: floor }} />
                <H3Bold dangerouslySetInnerHTML={{ __html: rooms }} />
                <H3Bold dangerouslySetInnerHTML={{ __html: area }} />
              </FlatDescriptionWrapper>

              <FlatImageWrapper>
                <LazyImage
                  src={image.src}
                  alt={image.alt!}
                  fill
                  objectFit="contain"
                />
              </FlatImageWrapper>
            </FlatWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
      <NavigationWrapper>
        <TextBody1>
          {activeIndex + 1} z {flats?.length ?? 1}
        </TextBody1>
        <NavigationButtonsWrapper>
          <IconButton aria-label="Prev flat" green onClick={goPrev}>
            <ArrowLeft />
          </IconButton>
          <IconButton aria-label="Next flat" green onClick={goNext}>
            <ArrowRight />
          </IconButton>
        </NavigationButtonsWrapper>
      </NavigationWrapper>
    </Wrapper>
  )
}
