import styled from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'
import { H1Light, H2Light } from 'components/atoms/Typography'

export const StyledH1Light = styled(H1Light)`
  max-width: 500px;
  margin: 0px auto;
`

export const StyledH2Light = styled(H2Light)`
  min-height: 120px;

  ${media.xl.max} {
    min-height: auto;
  }
`

export const ContentContainerWraper = styled.div`
  position: relative;

  ${media.xl.max} {
    width: 600px;
  }
  ${media.md.max} {
    width: auto;
  }
`

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const SmartDesignContainer = styled(Container)`
  max-width: calc(1280px + 160px);
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 48px;

  ${media.md.max} {
    padding: 32px 0px;
    gap: 24px;
  }
`

export const SmartDesignContentWraper = styled.div`
  display: flex;
  justify-content: center;

  ${media.xl.max} {
    width: fit-content;
    margin: 0px auto;
    flex-direction: column-reverse;
  }
`

export const SmartDesignContent = styled.div`
  width: 100%;
  max-width: 547px;

  margin-top: 40px;

  position: relative;
  left: 75px;
  z-index: 3;

  display: flex;
  flex-direction: column;
  gap: 64px;

  & h3 {
    max-width: 420px;
  }
  ${media.xl.max} {
    left: 0px;
    gap: 24px;
  }
  ${media.md.max} {
    padding: 0px 24px;
  }
`

export const ContentContainer = styled.div`
  width: calc(100% + 32px);

  padding: 40px;

  display: flex;
  flex-direction: column;
  gap: 32px;

  position: absolute;

  background-color: white;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.15);

  ${media.xl.max} {
    width: 100%;

    position: static;
  }
  ${media.sm.max} {
    padding: 24px;
  }
`
