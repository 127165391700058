import React from 'react'

import { ImageType } from 'types/image'

import { LazyImage } from 'components/atoms/Image'
import { H4Bold } from 'components/atoms/Typography/Typography.style'
import styled from 'styled-components'
import media from 'styles/media'
import {
  HeroContainer,
  HeroLable,
  HeroLableContent,
  HorizontalHr,
  ImageWrapper,
  HeroLableText,
  StyledH2Light,
} from './Hero.styles'

import { FindFlat } from './FindFlat'

type HeroProps = {
  heading: string
  description: string
  image: ImageType
}

const FindFlatWraper = styled.div`
  flex: 1 1;

  ${media.lg.max} {
    width: 100%;
    height: fit-content;

    bottom: -1px;
    transform: translate(0, 0%);

    padding: 24px;

    background-color: white;
    box-shadow: 4px 10px 20px rgba(0, 0, 0, 0.15);
  }
`

const HorizontalHrWraper = styled.div`
  ${media.lg.max} {
    width: 100%;
    padding: 0px 24px;
  }
`

export const Hero: React.FC<HeroProps> = ({ heading, description, image }) => {
  const HeroLableRef = React.useRef<HTMLDivElement>(null)
  const HeroLableTextRef = React.useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = React.useState(0)
  const [textContentHeight, setTextContentHeight] = React.useState(0)

  React.useEffect(() => {
    const updateHeight = () => {
      if (HeroLableRef.current) {
        setContentHeight(HeroLableRef.current.clientHeight)
      }
      if (HeroLableTextRef.current) {
        setTextContentHeight(HeroLableTextRef.current.clientHeight)
      }
    }

    updateHeight()

    window.addEventListener('resize', updateHeight)

    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [])

  return (
    <HeroContainer
      memoizedContentHeight={contentHeight}
      memoizedTextContentHeight={textContentHeight}
    >
      <ImageWrapper>
        <LazyImage
          src={image.src}
          alt={image.alt ?? ''}
          loading="eager"
          objectFit="cover"
        />
      </ImageWrapper>
      <HeroLable>
        <HeroLableContent>
          <HeroLableText ref={HeroLableTextRef}>
            <H4Bold
              as="h4"
              dangerouslySetInnerHTML={{
                __html: heading,
              }}
            />
            <StyledH2Light
              as="h1"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </HeroLableText>
          <HorizontalHrWraper>
            <HorizontalHr />
          </HorizontalHrWraper>
          <FindFlatWraper>
            <FindFlat />
          </FindFlatWraper>
        </HeroLableContent>
      </HeroLable>
    </HeroContainer>
  )
}
