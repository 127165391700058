import React from 'react'
import { navigate } from 'gatsby'
import { Formik } from 'formik'
import { toFormikValidate } from 'zod-formik-adapter'

import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'

import { MultiOptionSelect } from 'components/atoms/MultiOptionSelect'
import { H4Bold, TextBody2 } from 'components/atoms/Typography/Typography.style'
import useFlatContext from 'hooks/useFlatContext'
import useFilters, { Filters, filtersSchema } from 'hooks/useFilters'
import { toSorted } from 'utils/array'
import {
  FindFlatContainer,
  FormOptions,
  InputRangeContainer,
  InputRangeLable,
  InputRangeWraper,
  StyledForm,
  SelectContaiiner,
  StyledButton,
} from './FindFlat.styles'

export const FindFlat = () => {
  const { availableFlats } = useFlatContext()
  const { availableFilters } = useFilters(availableFlats)

  const initialValues: Filters = {
    rooms: [],
    floor: availableFilters.floor,
    area: {
      ...availableFilters.area,
    },
  }

  const handleFromSubmit = (values: Filters) => {
    const filters = {
      ...values,
      rooms: values.rooms.length !== 0 ? values.rooms : availableFilters.rooms,
    }
    navigate('/wyszukiwarka', { state: { filters } })
  }

  return (
    <FindFlatContainer>
      <H4Bold as="h4">
        Znajdź <span className="text-green">Swoje</span> mieszkanie
      </H4Bold>

      <Formik
        initialValues={initialValues}
        validate={toFormikValidate(filtersSchema)}
        onSubmit={(values) => handleFromSubmit(values)}
      >
        {({
          values,
          // handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <StyledForm onSubmit={handleSubmit}>
            <FormOptions>
              <SelectContaiiner>
                <label htmlFor="numberOfRooms">
                  <TextBody2>Liczba pokoi</TextBody2>
                </label>
                <MultiOptionSelect
                  options={availableFilters.rooms.map((room) => ({
                    label: `${room}`,
                    value: `${room}`,
                  }))}
                  selected={values.rooms.map((room) => `${room}`)}
                  label={
                    values.rooms.length !== 0
                      ? `Pokoje: ${values.rooms.join(', ')}`
                      : 'Wybierz'
                  }
                  onValueClick={(value) => {
                    if (values.rooms.includes(Number(value))) {
                      setFieldValue(
                        'rooms',
                        values.rooms.filter((room) => room !== Number(value))
                      )
                    } else {
                      setFieldValue(
                        'rooms',
                        toSorted([...values.rooms, Number(value)])
                      )
                    }
                  }}
                />
              </SelectContaiiner>
              <InputRangeContainer>
                <TextBody2>Powierzchnia</TextBody2>
                <InputRangeWraper>
                  <InputRange
                    minValue={initialValues.area.min}
                    maxValue={initialValues.area.max}
                    step={1}
                    value={values.area}
                    onChange={(value) => setFieldValue('area', value)}
                  />
                </InputRangeWraper>
                <InputRangeLable>
                  <TextBody2>
                    {initialValues.area.min} m<sup>2</sup>
                  </TextBody2>
                  <TextBody2>
                    {initialValues.area.max} m<sup>2</sup>
                  </TextBody2>
                </InputRangeLable>
              </InputRangeContainer>
            </FormOptions>
            <StyledButton TextButton2 type="submit">
              Szukaj
            </StyledButton>
          </StyledForm>
        )}
      </Formik>
    </FindFlatContainer>
  )
}
