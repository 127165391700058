import styled from 'styled-components'
import media from 'styles/media'

import { Button } from 'components/atoms/Button'
import { Link } from 'components/atoms/Typography'

export const InputRangeContainer = styled.div`
  width: calc(50% - 8px);
  min-width: 160px;

  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;

  ${media.sm.max} {
    width: 100%;
  }
`

export const StyledLink = styled(Link)`
  ${media.xxl.max} {
    width: 100%;
  }
`

export const StyledButton = styled(Button)`
  ${media.xxl.max} {
    max-width: none;
    width: 100%;
  }
`

export const FindFlatContainer = styled.div`
  width: 100%;
  min-width: 290px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.sm.max} {
    min-width: 0px;
  }
`

export const InputRangeLable = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 12px;
  line-height: 18px;
`

export const SelectContaiiner = styled.div`
  width: calc(50% - 8px);
  min-width: 160px;

  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;

  ${media.sm.max} {
    width: 100%;
  }
`

export const FormOptions = styled.div`
  width: 100%;

  display: flex;
  gap: 16px;
  flex: 1;

  ${media.xl.max} {
    justify-content: space-around;
  }
  ${media.sm.max} {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`

export const StyledForm = styled.form`
  margin: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  ${media.xxl.max} {
    flex-direction: column;
  }
`

export const InputRangeWraper = styled.div`
  min-width: fit-content;

  padding: 15px 24px;

  flex: 0 1;

  border: 1px solid ${({ theme }) => theme.colors.gray05};
  border-radius: 32px;

  & .input-range {
    position: relative;
  }

  & .input-range__label--max,
  .input-range__label--min,
  .input-range__label-container {
    opacity: 0;
    transform: scale(0);
    user-select: none;
  }

  & .input-range__track,
  .input-range__slider-container {
    transition: none !important;
  }
  .input-range__slider {
    transform: scale(1);

    &:active {
      transform: scale(1.1);
    }
  }

  .input-range__slider-container {
    bottom: 0px;
  }

  & .input-range__slider-container:active .input-range__label-container {
    display: block;
    padding: 4px 8px;

    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    font-family: 'Kumbh Sans Variable', sans-serif;

    color: white;
    background-color: black;
    border-radius: 8px;

    position: absolute;
    top: -10px;
    left: -20px;

    transform: scale(1);
    opacity: 1;

    transition: all 0.3s ease;

    &::after {
      content: ' m²';
    }
  }

  & .input-range__track--active,
  .input-range__slider {
    background: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.white};
  }
`
